:root {
  --blue: #002d72;
  --orange: #f26e21;
  --hoverTransition: 0.15s;
  --pageLoadAnimation: 0.5s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
  font-family: "EB Garamond", serif;
  font-size: 16px;
  min-height: 100vh;
  width: 100%;
  padding: 1.5rem 3rem;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:not([class="link"]) {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image-gallery,
.image-gallery:fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Prevent images from overflowing the image gallery */
.image-gallery-content {
  max-width: 100%;
}

.image-gallery-image {
  margin-inline: 2rem;
  max-width: 75%;
  padding-inline: 2rem;
}

/* Change the image gallery's navigation icons color */
.image-gallery-icon.image-gallery-left-nav,
.image-gallery-icon.image-gallery-right-nav,
.image-gallery-icon.image-gallery-fullscreen-button {
  color: black;
  filter: drop-shadow(0 0 0 white);
  outline: none;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 90px;
  width: 45px;
}

.image-gallery:fullscreen .image-gallery-icon.image-gallery-left-nav,
.image-gallery:fullscreen .image-gallery-icon.image-gallery-right-nav,
.image-gallery:fullscreen .image-gallery-icon.image-gallery-fullscreen-button,
.image-gallery-icon.image-gallery-left-nav:hover,
.image-gallery-icon.image-gallery-right-nav:hover,
.image-gallery-icon.image-gallery-fullscreen-button:hover {
  color: var(--orange);
}

/* Reset the max-width on mobile screens */
@media screen and (max-width: 768px) {
  .image-gallery-content {
    max-width: 100%;
  }
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
}

.mosaic {
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  gap: 10px;
  justify-content: center;
  animation: animateMosaic var(--pageLoadAnimation);
}

@keyframes animateMosaic {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

[data-image="pixelated"],
[data-image="regular"] {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
}

[data-image="pixelated"] {
  image-rendering: pixelated;
  z-index: 2;
}

[data-image="pixelated"]:hover {
  display: none;
}

/* On mobile screens, continue displaying pixelated images during mouse hover */
@media screen and (max-width: 768px) {
  [data-image="pixelated"]:hover {
    display: block;
  }
}

[data-image="regular"] {
  z-index: 1;
}

.hover-left:hover,
.hover-right:hover {
  transition: transform 0.45s;
  z-index: 100;
}

.hover-right:hover {
  transform: scale(3.4) translateX(1.1rem) translateY(1.1rem);
}
.hover-left:hover {
  transform: scale(3.4) translateX(-1.1rem) translateY(1.1rem);
}

/* Hover upwards */
.hover-right.up:hover {
  transform: scale(3.4) translateX(1.1rem) translateY(-1.1rem);
}
.hover-left.up:hover {
  transform: scale(3.4) translateX(-1.1rem) translateY(-1.1rem);
}

.not-found {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50%;
}
